import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Container,
  Section,
  Seo,
} from '@components'
import { OfferForm } from '../../../components/site/offer'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'

const offer = 'mobapisec'
const imgDir = '../../../components/site/images'
const imgFile = 'Mobile-API-Protection-eBook.jpg'
const imgAlt = 'Mobile API Security eBook'
const headlineTitle = 'Mobile API Security: Using Keys and Tokens'
const formTitle = 'Get the eBook'

const Headline = () => {
  return (
    <Section>
      <Container className="mb-8 container-lg">
        <h1 className="mt-12 mb-4 text-3xl text-center">{headlineTitle}</h1>
      </Container>
    </Section>
  )
}

const Pitch = () => {
  return (
    <div>
      <div className="mx-8 lg:mx-8">
        <StaticImage
          src={`${imgDir}/${imgFile}`}
          alt={imgAlt}
          placeholder="blurred"
        />
      </div>
    </div>
  )
}

const Action = () => {
  return (
    <div>
      <h3 className="mt-4 mb-2 text-2xl text-center text-gray-700 lg:mt-0">
        {formTitle}
      </h3>
      <OfferForm className="pt-0 pl-4 pr-4" offer={offer} />
    </div>
  )
}

const LandingPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>

      <Headline />

      <Section>
        <Container className="mb-8 container-lg">
          <div className="flex flex-col justify-center lg:flex-row lg:content-center lg:items-center">
            <div className="flex items-center justify-center lg:w-6/12">
              <Pitch />
            </div>

            <div className="lg:pl-24 lg:pr-0 lg:w-6/12">
              <Action />
            </div>
          </div>
        </Container>
      </Section>
    </LandingPageLayout>
  )
}

export default LandingPage
